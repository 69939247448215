import backgroundVideo from "./videos/video.mp4"
import backgroundImage from "./images/image.jpg"
import "./App.css"
import React from "react"
import Stories from "./components/Stories"

export const baseUrl = "https://api.storieswall.com"

const App = () => {
    console.log("App")

    return (
        <div className="safe-zone">
            <video className="video-background" type="video/mp4" src={backgroundVideo} loop autoPlay muted playsInline poster={backgroundImage}></video>
            <Stories />
        </div>
    )
}

export default App;
