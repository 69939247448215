import React from 'react'
import styles from './story.module.css';
import { baseUrl } from '../../App';

const Story = (props) => {
    console.log('Story')
    if (props.story.extension === '.jpg') {
        return (
            <div>
                <img className={styles.story__img} src={`${baseUrl}/files/${props.story.src}`} alt={props.story.alt}></img>
            </div>
        )
    }
    else {
        return (
            <div>
                <video className={styles.story__vid} src={`${baseUrl}/files/${props.story.src}`} loop autoPlay muted playsInline></video>
            </div>
        )
    }
}

export default Story;
